<template>
  <ion-page>
    <!-- If User is Authorized -->
    <div>
        <!-- Page Header -->
      <ion-header>
        <ion-toolbar color="dark">
          <ion-row>
            <!-- Left Col -->
            <ion-col size="3">
              &nbsp;
            </ion-col>
            <!-- Main Col -->
            <ion-col size="3">
              &nbsp;
            </ion-col>
            <!-- Right Col -->
            <ion-col size="6" class="ion-text-right">
              <!-- Can Skip -->
              <ion-button 
                v-if="canSkip === true"
                color="light"
                @click.prevent="skipStep()">
                Skip
              </ion-button>
              <!-- Can Not Skip -->
              <ion-button 
                v-if="canSkip === false"
                color="light"
                disabled>
                Skip
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-toolbar>
      </ion-header>
      <!-- Page Content -->
      <ion-content color="dark" :fullscreen="true">
        <!-- Home Feed -->
        <div id="container">
          <div class="app-max-width">
            <!-- Profile Photo -->
            <div v-if="currentStep === 'profile-photo'">
              <ProfilePhoto :store="store.state" @component-data="getData" />
            </div>          
            <!-- Location -->
            <div v-if="currentStep === 'profile-location'">
              <ProfileLocationWrapper :store="store.state" @component-data="getData" />
            </div>
            <!-- Profile Genres -->
            <div v-if="currentStep === 'profile-genres' && appId === 1">
              <ProfileGenres :store="store.state" @component-data="getData" />
            </div>
            <!-- Profile About -->
            <div v-if="currentStep === 'profile-short-description'">
              <ProfileAbout :store="store.state" @component-data="getData" />
            </div>
            <!-- Profile Genres -->
            <div v-if="currentStep === 'profile-complete'">
              <p class="text-light">This screen will inform the user that they have completed their profile. It will also display profiles and content that they user maybe interested in following based on their location and genres.</p>
              <ion-button @click="updateAccountStatus()">Home</ion-button>
            </div>   

          </div>
        </div>
      </ion-content>
    </div>
  </ion-page>
</template>

<script>
import { IonContent, IonPage, IonHeader, IonToolbar, IonRow, IonCol, IonButton, loadingController, toastController } from '@ionic/vue';
import { defineComponent, ref, onMounted, computed } from 'vue';
import ProfilePhoto from '../components/ProfilePhoto.vue'
import ProfileGenres from '../components/ProfileGenres.vue'
import ProfileAbout from '../components/ProfileAbout.vue'
import ProfileLocationWrapper from '../components/ProfileLocationWrapper.vue'
import apiClient from '../services/api';
import { useRouter } from 'vue-router';
import { useStore } from "vuex";

export default defineComponent({
  name: 'OnBoarding',
  props: [],
  components: {
    IonContent,
    IonPage,
    IonHeader, 
    IonToolbar, 
    IonRow, 
    IonCol,
    IonButton,
    ProfilePhoto,
    ProfileGenres,
    ProfileLocationWrapper,
    ProfileAbout, 
  },
  setup() {
    const router = useRouter();
    const currentStep = ref(null);
    const canSkip = ref(true);
    const timeout = { default: 1000 }
    const store = useStore();
    const appId = computed(() => store.state.appId);
    const authUser = computed(() => store.state.authUser);
    const authToken = computed(() => store.state.authToken);
    const authId = ref(0);
    console.log(store.state)
    onMounted(() => {
      isUserAuthenticated() 
      getStep()
    })

    function getStep() {
      // Onboarding page has just been mounted / Determine current step
      if(currentStep.value === null) {
        // Avatar is not set
        if(authUser.value.profile_photo_path == null || authUser.value.profile_photo_path == '') {
          currentStep.value = 'profile-photo'
          canSkip.value = true 
        }
        // Avatar is set
        if(authUser.value.profile_photo_path != null && authUser.value.profile_photo_path != '') {
          // Location not set
          if(authUser.value.location == null || authUser.value.location == '') {
            currentStep.value = 'profile-location'
            canSkip.value = false                
          }
          // Location is set
          else {
            // Genres is not set
            if(authUser.value.genres === null || authUser.value.genres.length < 1) {
              currentStep.value = 'profile-genres'
              canSkip.value = false                 
            }
            // Genres is set
            else {
              // About not set
              if(authUser.value.profile.short_description == null || authUser.value.profile.short_description == '') {
                currentStep.value = 'profile-short-description'
                canSkip.value = true                
              }
              // About is set
              else {
                currentStep.value = 'profile-complete'
                canSkip.value = true 
              }
            }
          }
        }
      } 
      // Step has just changed
      else {
        // Profile Photo
        if(currentStep.value == 'profile-photo') {
          // Avatar not set
          if(authUser.value.profile_photo_path == null || authUser.value.profile_photo_path == '') {
            canSkip.value = true 
          }
          // Avatar is set
          else {
            // Location not set
            if(authUser.value.location == null || authUser.value.location == '' || authUser.value.location == 0) {
              currentStep.value = 'profile-location'
              canSkip.value = false                
            } 
            // Location is set
            else {
              // Genres is not set
              if(authUser.value.genres === null || authUser.value.genres.length < 1) {
                currentStep.value = 'profile-genres'
                canSkip.value = false                 
              } 
              // Genres is set
              else {
                // About not set
                if(authUser.value.profile.short_description == null || authUser.value.profile.short_description == '') {
                  currentStep.value = 'profile-short-description'
                  canSkip.value = true                
                }
                // About is set
                else {
                  currentStep.value = 'profile-complete'
                  canSkip.value = true 
                }
              }             
            }           
          }
        }
        // Profile Location
        if(currentStep.value == 'profile-location') {
          // Location not set
          if(authUser.value.location == null || authUser.value.location == '' || authUser.value.location == 0) {
            currentStep.value = 'profile-location'
            canSkip.value = false                
          } 
          // Location is set
          else {
            // Genres is not set
            if(authUser.value.genres === null || authUser.value.genres.length < 1) {
              currentStep.value = 'profile-genres'
              canSkip.value = false                 
            } 
            // Genres is set
            else {
              // About not set
              if(authUser.value.profile.short_description == null || authUser.value.profile.short_description == '') {
                currentStep.value = 'profile-short-description'
                canSkip.value = true                
              }
              // About is set
              else {
                currentStep.value = 'profile-complete'
                canSkip.value = true 
              }
            }             
          } 
        }
        // ProfileGenres
        if(currentStep.value == 'profile-genres') {
          // Genres is not set
          if(authUser.value.genres === null || authUser.value.genres.length < 1) {
            currentStep.value = 'profile-genres'
            canSkip.value = false                 
          } 
          // Genres is set
          else {
            // About not set
            if(authUser.value.profile.short_description == null || authUser.value.profile.short_description == '') {
              currentStep.value = 'profile-short-description'
              canSkip.value = true                
            }
            // About is set
            else {
              currentStep.value = 'profile-complete'
              canSkip.value = true 
            }
          }             
        }
        if(currentStep.value == 'profile-short-description') {
          // About not set
          if(authUser.value.profile.short_description == null || authUser.value.profile.short_description == '') {
            currentStep.value = 'profile-short-description'
            canSkip.value = true                
          }
          // About is set
          else {
            currentStep.value = 'profile-complete'
            canSkip.value = true 
          }          
        }
        if(currentStep.value == 'profile-complete') {
            canSkip.value = true          
        }
      }
    }

    function skipStep() {
      // If current step is Profile Photo
      if(currentStep.value == 'profile-photo') {
        if(authUser.value.location != null) {
          if(authUser.value.genres.length > 0) {
            currentStep.value = 'profile-short-description'
            canSkip.value = true  
          } else {
            currentStep.value = 'profile-genres'
            canSkip.value = false             
          }
        } else {
          currentStep.value = 'profile-location'
          canSkip.value = false 
        }
      }
      // If current step is Profile Short Description
      if(currentStep.value == 'profile-short-description') {
        currentStep.value = 'profile-complete'
        canSkip.value = true          
      }
      // If current step is Profile Complete
      if(currentStep.value == 'profile-complete') {
        updateAccountStatus()
        canSkip.value = true          
      }
    }

    function updateAccountStatus() {
      presentLoading()
      apiClient.post('/api/update/account/status', {
        status: 1           
      },
      {
        headers: {
          'Authorization':  `Bearer ${authToken.value}`
        }
      }).then(response => {
        console.log(response)
        localStorage.setItem('authUser', JSON.stringify(response.data.user));
       // store.commit('setAuthUser', response.data.user);
        //store.commit('setActiveUser', true);
        openToast()
        router.push('home')
      }).catch(error => {
        console.log(error)  
        // credentials didn't match
        //this.isError = true
        //this.errors = error.response.data.errors
      });
    }

    async function presentLoading() {
      const loading = await loadingController
      .create({
        cssClass: 'my-custom-class',
        message: 'Please wait...',
        duration: timeout,
      });

      await loading.present();

      setTimeout(function() {
        loading.dismiss()
      }, timeout);
    }

    async function openToast() {
      const toast = await toastController
      .create({
        //message: '',
        duration: 2000,
        message: 'Your profile has been updated.',
        position: 'bottom',
        buttons: [
          {
            icon: 'close',
            role: 'cancel',
            handler: () => {

            }
          }
        ]
      })
      return toast.present();
    }

    function isUserAuthenticated() {
      if(localStorage.getItem('authToken')) {
        store.commit('setAuthToken', localStorage.getItem('authToken'));
        var user = JSON.parse(localStorage.getItem('authUser'));
        if(user) {
          if(user.id != null) {
            authId.value = user.id
          }
          getUserData()
          store.commit('setIsAuth', true);

        } else {
          store.commit('setIsAuth', false);
        }

      } else {
        store.commit('setIsAuth', false);
        router.push('/sign-in')
      }
    }

    function getUserData() {
      presentLoading()
      apiClient.get('/api/get/user/' + authId.value, {
        headers: {
        'Authorization':  `Bearer ${authToken.value}`
      }
      }).then(response => {
        localStorage.setItem('authUser', JSON.stringify(response.data.user));
        store.commit('setAuthUser', response.data.user);
        if(authUser.value.status == 1) {
          store.commit('setActiveUser', true);
          router.push('home')
        } else {
          store.commit('setActiveUser', false);
        }
      }).catch(error => {
        console.log(error)  
      });
    }

    function updateUserData(user) {
      store.commit('setAuthUser', user);
    }

    return {
      authUser, currentStep, canSkip, skipStep, getStep, presentLoading, updateAccountStatus, router, store, appId, authId, updateUserData
    }
  },
  methods: {
    // Used on all other components 
    getData: function(e) {
      if(e.dataType == 'user') {
        this.updateUserData(e.data)
      }
    },
  },
  watch: {
    authUser: {
      handler() {
        this.getStep()
      },
      deep:true
    }
  }
});
</script>

<style scoped>
#container {
  text-align: center;
  
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;
  
  color: #8c8c8c;
  
  margin: 0;
}

#container a {
  text-decoration: none;
}
</style>